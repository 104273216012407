html,
body {

  padding: 0;
  margin: 0 auto;
  font-family: Apple SD Gothic Neo;
  font-style: normal;
  font-weight: normal;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}



.flex {
  display: flex;
}




.content {
  position: relative;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: hidden;
  /* max-width: 1280px; */
}






.register-form {
  position: relative;

  &:before {
    display: block;
    content: '';
    width: 236px;
    height: 214px;
    // background: url("./img/card.png") no-repeat center center;
    position: absolute;
    right: 84%;
    bottom: -40px;
    
  }
}






@media(min-width:1480px) {

}



@media(max-width:1080px) {
 
}


